import React, { useEffect, useState } from 'react';
import Header from "../components/header"
import {Helmet} from "react-helmet";
import Scroll from '../components/scroll';
import Footer from "../components/footer";
import friends from "../images/friends.webp";
import hookups from "../images/hooks.webp";
import rel from "../images/rel.webp";
import i18next from "i18next";


const PrivacyPage = () => {

  const [isLocalStorageAvailable, setLocalStorageAvailable] = useState(false);
  const [language, setLanguage] = useState('en');  // default language

  useEffect(() => {
    if (typeof localStorage !== 'undefined') {
      setLocalStorageAvailable(true);
      const lang = localStorage.getItem('lang') || 'en';  // default to 'en' if not set
      setLanguage(lang);
    }
  }, []);
  const [privacyData] = React.useState({
    "id": 1,
    "attributes": {
        "name": "",
        "adress": "Rue de la Thyria 14, 5651 Thy-le-Chateau",
        "idNumber": "BE0785441266",
        "dpo_mailAdress": "a.lesire@rejoiceapp.io",
        "privacy_mailAdress": "privacy@rejoiceapp.io",
        "substractors": ['Weaverize'],
        "dpa_url": "www.autoriteprotectiondonnees.be/",
        "dpa_adress": "Rue de la Presse 35, 1000 Bruxelles",
        "dpa_phone": "+32 (0)2 274 48 00",
        "dpa_fax": "+32 (0)2 274 48 35",
        "dpa_mail": "contact@apd-gba.be",
        "lastUpdate": "09/03/2023"
    }
})

  return (
    <main>
      <Helmet htmlAttributes={{ lang: language }}>
        <meta charSet="utf-8" />
        <meta name="description" content="Rejoice is a dating app designed to help you find your soulmate, new friends or someone to hookup with based on your compatibility" />
        <meta name="keywords" content="Rejoice, dating, app, new, big5, concept, young, ipq, meet, people, girls" />
        <meta name="author" content="Rejoice" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>{i18next.t("privacy.header.title")}</title>
      </Helmet>
      <Scroll showBelow={250} />
      <Header />
      <div className="md:relative py-16 bg-white overflow-hidden text-justify">
        <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
          <div className="md:relative h-full text-lg max-w-prose mx-auto" aria-hidden="true">
            <svg
              className="absolute top-12 left-full transform translate-x-32"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
            >
              <defs>
                <pattern
                  id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width={404} height={384} fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)" />
            </svg>
            <svg
              className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
            >
              <defs>
                <pattern
                  id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width={404} height={384} fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)" />
            </svg>
            <svg
              className="absolute bottom-12 left-full transform translate-x-32"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
            >
              <defs>
                <pattern
                  id="d3eb07ae-5182-43e6-857d-35c643af9034"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width={404} height={384} fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)" />
            </svg>
          </div>
        </div>
        <div className="md:relative px-4 sm:px-6 lg:px-8">
          <div className="text-lg max-w-prose mx-auto">
            <h1>
              <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              {i18next.t("privacy.title")}
              </span>
            </h1>
          </div>

          <div className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
            <h2>{i18next.t("privacy.block1.title")}</h2>
            <p className="mt-8 text-xl text-gray-500 leading-8">
            {i18next.t("privacy.block1.text.p1")}
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
                      </p>
            <h2>{i18next.t("privacy.block2.title")}</h2>
            <p className="mt-8 text-xl text-gray-500 leading-8">
            {i18next.t("privacy.block2.text.p1")}
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
            {i18next.t("privacy.block2.text.p2")}<strong>{privacyData.attributes.adress} </strong>         </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
            {i18next.t("privacy.block2.text.p3")} : <strong>{privacyData.attributes.idNumber} </strong>          </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
            {i18next.t("privacy.block2.text.p4")}     <a href={"mailto:"+privacyData.attributes.dpo_mailAdress}>{privacyData.attributes.dpo_mailAdress}</a>       </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
            {i18next.t("privacy.block2.text.or")}            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
            {i18next.t("privacy.block2.text.p5")} <a href={"mailto:"+privacyData.attributes.privacy_mailAdress}>{privacyData.attributes.privacy_mailAdress}</a>
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
            {i18next.t("privacy.block2.text.p6")}             </p>
            <h2>{i18next.t("privacy.block3.title")} </h2>
            <p className="mt-8 text-xl text-gray-500 leading-8">
            {i18next.t("privacy.block3.text.p1")}            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
            {i18next.t("privacy.block3.text.p2")}   
            </p>
            <table className="table-auto">
              <thead>
                <tr>
                  <th>{i18next.t("privacy.block3.table.column1.title")}   </th>
                  <th>{i18next.t("privacy.block3.table.column2.title")}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{i18next.t("privacy.block3.table.column1.rows.row1")}<br /> <br />{i18next.t("privacy.block3.table.column1.rows.row2.title")}<br />
                  <ul>
                    <li>{i18next.t("privacy.block3.table.column1.rows.row2.list.1")}</li>
                    <li>{i18next.t("privacy.block3.table.column1.rows.row2.list.2")}</li>
                    <li>{i18next.t("privacy.block3.table.column1.rows.row2.list.3")}</li>
                  </ul></td>
                  <td>{i18next.t("privacy.block3.table.column2.rows.row1")}</td>
                </tr>
                <tr>
                  <td>{i18next.t("privacy.block3.table.column1.rows.row3")}  <br /> <br />{i18next.t("privacy.block3.table.column1.rows.row4.title")}<br /><ul>
                    <li>{i18next.t("privacy.block3.table.column1.rows.row4.list.1")}</li>
                    <li>{i18next.t("privacy.block3.table.column1.rows.row4.list.2")}</li>
                    <li>{i18next.t("privacy.block3.table.column1.rows.row4.list.3")}</li>
                    <li>{i18next.t("privacy.block3.table.column1.rows.row4.list.4")}</li>
                    <li>{i18next.t("privacy.block3.table.column1.rows.row4.list.5")}</li>
                    <li>{i18next.t("privacy.block3.table.column1.rows.row4.list.6")}</li>
                  </ul></td>
                  <td>{i18next.t("privacy.block3.table.column2.rows.row2")}</td>
                </tr>
              </tbody>
            </table>
            <h2>{i18next.t("privacy.block4.title")}</h2>
            <p className="mt-8 text-xl text-gray-500 leading-8">
            {i18next.t("privacy.block4.text.p1")}            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
            {i18next.t("privacy.block4.text.p2")}               </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
            {i18next.t("privacy.block4.text.p3")}               </p>
            <table className="table-auto">
              <thead>
                <tr>
                  <th>{i18next.t("privacy.block4.table.column1.title")}   </th>
                  <th>{i18next.t("privacy.block4.table.column2.title")}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{i18next.t("privacy.block4.table.column1.row")}</td>
                  <td>{i18next.t("privacy.block4.table.column2.row")}</td>
                </tr>
              </tbody>
            </table>
            <h2>{i18next.t("privacy.block5.title")}</h2>
            <p className="mt-8 text-xl text-gray-500 leading-8">
            {i18next.t("privacy.block5.text")}            </p>
            <h2>{i18next.t("privacy.block6.title")}</h2>
            <p className="mt-8 text-xl text-gray-500 leading-8">
            {i18next.t("privacy.block6.text.p1")}            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
            {i18next.t("privacy.block6.text.p2")}               </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
            {i18next.t("privacy.block6.text.p3")}               </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
            {i18next.t("privacy.block6.text.p4")}               </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
            {i18next.t("privacy.block6.text.p5")}               </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
            {i18next.t("privacy.block6.text.p6")}               </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              <strong>{i18next.t("privacy.block6.text.currentSub")}   </strong>
            </p>
            <ul>
              {privacyData.attributes.substractors ? privacyData.attributes.substractors.map((e,i)=>(
                <li key={i}>{e}</li>
              ))
              :
              null
              }
            </ul>
            <h2>{i18next.t("privacy.block7.title")}   </h2>
            <p className="mt-8 text-xl text-gray-500 leading-8">
            {i18next.t("privacy.block7.text.p1")}            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
            {i18next.t("privacy.block7.text.p2")}             </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
            {i18next.t("privacy.block7.text.p3")}             </p>
            <ul>
              <li>{i18next.t("privacy.block7.text.list.1")} </li>
              <li>{i18next.t("privacy.block7.text.list.2")}</li>
            </ul>
            <h2>{i18next.t("privacy.block8.title")}</h2>
            <p className="mt-8 text-xl text-gray-500 leading-8">
            {i18next.t("privacy.block8.text.p1")}            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
            {i18next.t("privacy.block8.text.p2")}            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
            {i18next.t("privacy.block8.text.p3")} <a href={"mailto:"+privacyData.attributes.privacy_mailAdress}>{privacyData.attributes.privacy_mailAdress}</a>
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
            {i18next.t("privacy.block8.text.list.text")}            </p>
            <ul>
              <li>{i18next.t("privacy.block8.text.list.1")}    </li>
              <li>{i18next.t("privacy.block8.text.list.2")} </li>
              <li>{i18next.t("privacy.block8.text.list.3")} </li>
            </ul>
            <p className="mt-8 text-xl text-gray-500 leading-8">
            {i18next.t("privacy.block8.text.p4")}  <br />{i18next.t("privacy.block8.text.p5")} <br />{i18next.t("privacy.block8.text.p6")}  <a href={"mailto:"+privacyData.attributes.privacy_mailAdress}>{privacyData.attributes.privacy_mailAdress}</a>.
            </p>
            <h2>{i18next.t("privacy.block9.title")} </h2>
            <p className="mt-8 text-xl text-gray-500 leading-8">
            {i18next.t("privacy.block9.text.p1")}            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
            {i18next.t("privacy.block9.text.p2")}                </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              <a href={privacyData.attributes.dpa_url}>{privacyData.attributes.dpa_url}</a> <br />{privacyData.attributes.dpa_adress}<br />{i18next.t("privacy.block9.text.contact.phone")} :  <a href={"tel:"+privacyData.attributes.dpa_phone}>{privacyData.attributes.dpa_phone}</a><br />{i18next.t("privacy.block9.text.contact.fax")}  : {privacyData.attributes.dpa_fax}<br />{i18next.t("privacy.block9.text.contact.mail")}   : <a href={"mailto:"+privacyData.attributes.dpa_mail}>{privacyData.attributes.dpa_mail} </a>
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
            {i18next.t("privacy.block9.text.p3")}              </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
            {i18next.t("privacy.block9.text.p4")}  <a href="https://www.autoriteprotectiondonnees.be/introduire-une-requ%C3%AAteune-plainte">https://www.autoriteprotectiondonnees.be/introduire-une-requ%C3%AAteune-plainte</a>
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
            {i18next.t("privacy.block9.text.p5")}              </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
            {i18next.t("privacy.block9.text.p6")}  <a href={"mailto:"+privacyData.attributes.privacy_mailAdress}>{privacyData.attributes.privacy_mailAdress}</a>
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
            {i18next.t("privacy.block9.text.p7")}              </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
            {i18next.t("privacy.block9.text.p8")}              </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
            {i18next.t("privacy.block9.text.p9")}              </p>
            <h2>{i18next.t("privacy.block10.title")}  </h2>
            <p className="mt-8 text-xl text-gray-500 leading-8">
            {i18next.t("privacy.block10.text.p1")}             </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
            {i18next.t("privacy.block10.text.p2")}              </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              <strong>{privacyData.name}</strong> {i18next.t("privacy.block10.text.p3")}  
            </p>
            <h2>{i18next.t("privacy.block11.title")}  </h2>
            <p className="mt-8 text-xl text-gray-500 leading-8">
            {i18next.t("privacy.block11.text.p1")}              </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
            {i18next.t("privacy.block11.text.p2")}             </p>
            <h2>{i18next.t("privacy.block12.title")}</h2>
            <p className="mt-8 text-xl text-gray-500 leading-8">
            {i18next.t("privacy.block12.text.p1")}            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
            {i18next.t("privacy.block12.text.p2")}                  </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
            {i18next.t("privacy.block12.text.p3")} : {new Date(privacyData.attributes.lastUpdate).toString()}                 </p>
          </div>
        </div>
      </div>
      <Footer />
      <div className="mx-auto hidden" id="find">
        <div className="pt-32"></div>
        <h2 className="text-4xl text-center font-bold darkpink">Nous avons pensé Rejoice pour vous aider à...</h2>
        <div className="flex flex-col lg:flex-row lg:pt-16 w-11/12 mx-auto">
          <div className="mx-auto relative mt-12 lg:mt-0 text-center w-11/12 lg:w-1/3">
            <div className="relative lg:mt-60 mx-auto z-0  w-full">
              <img src={friends} alt="friends" className="shadow-faq-box rounded-2xl w-full h-full" />
              <h3 className="poppin font-semibold pinkperfect text-xl text-center mt-3">Créer des amitiés</h3>
            </div>
          </div>
          <div className="mx-auto relative mt-24 lg:mt-12 lg:mr-24 lg:ml-24 text-center w-11/12 lg:w-1/3">
            <div className=" relative mx-auto  z-0 w-full ">
              <img src={rel} alt="Relationship" className="shadow-faq-box rounded-2xl w-full h-full" />
              <h3 className="poppin font-semibold pinkperfect text-xl text-center mt-3">Trouver votre âme soeur</h3>
            </div>
          </div>
          <div className="mx-auto relative mt-24 lg:mt-0 text-center w-11/12 lg:w-1/3">
            <div className="relative lg:mt-60 mx-auto z-0 w-full ">
              <img src={hookups} alt="Hookups" className="shadow-faq-box rounded-2xl w-full h-full" />
              <h3 className="poppin font-semibold pinkperfect text-xl text-center mt-3">faire des rencontres</h3>
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}
export default PrivacyPage